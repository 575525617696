import React, {useState, useEffect, useRef} from 'react';
import {Input, Modal, Select, DatePicker, Tooltip} from 'antd';
import Card from '@mui/material/Card';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

import MDBox from '../../components/MDBox';
import MDButton from '../../components/MDButton';
import MDTypography from '../../components/MDTypography';
import DashboardLayout from '../../layouts/DashboardLayout';
import DashboardNavbar from '../../components/DashboardNavbar';
import DataTable from '../../components/DataTable';
import LabelFilterView from '../../components/LabelFilterView';
import ClientDropdown from '../../components/ClientDropdown';

import Styles from './styles';
import Strings from '../../../constants/strings';
import Jsons from '../../../constants/jsons';
import {fetchAlertsApiCall} from '../../../services/api/apiCalls';
import {
  getQueryParams,
  formatDate,
  formatDateAndTime,
  formatPrice,
} from '../../../utility/helpers';

const MerchantPage = () => {
  const inputRef = useRef(null);
  const {RangePicker} = DatePicker;

  const [storeAlerts, setStoreAlerts] = useState([]);
  const [paginationMeta, setPaginationMeta] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [alertInfoData, setAlertInfoData] = useState('');
  const [storeClients, setStoreClients] = useState([]);

  const [descriptor, setDescriptor] = useState('');
  const [caseId, setCaseId] = useState('');
  const [dataSource, setDataSource] = useState(null);
  const [caseDate, setCaseDate] = useState(null);
  const [caseDateString, setCaseDateString] = useState(null);
  const [status, setStatus] = useState(null);
  const [clientId, setClientId] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    getAlertsListData(currentPage);
  }, []);

  // api call code
  const getAlertsListData = async page => {
    setIsLoading(true);
    setCurrentPage(page);

    const params = {
      descriptor,
      caseNumber: caseId,
      dataSource,
      caseStartDate: caseDate && caseDate[0],
      caseEndDate: caseDate && caseDate[1],
      processStatus: status,
      clientId,
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchAlertsApiCall(queryString);
    if (responseData?.success) {
      setStoreAlerts(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  const handleResetClicked = async page => {
    setDescriptor(null);
    setCaseId(null);
    setDataSource(null);
    setCaseDate(null);
    setCaseDateString(null);
    setStatus(null);
    setClientId(null);

    setIsLoading(true);
    setCurrentPage(page);

    const params = {
      page,
    };
    const queryString = getQueryParams(params);

    const responseData = await fetchAlertsApiCall(queryString);
    if (responseData?.success) {
      setStoreAlerts(responseData?.data);
      setPaginationMeta(responseData?.meta);
    }

    setIsLoading(false);
  };

  // logical code
  const handleDetailsClicked = async item => {
    setAlertInfoData(item);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setAlertInfoData('');
  };

  const getStatusText = data => {
    const processStatus = data?.processStatus;
    if (!processStatus) return '--';

    return Jsons.alertStatus.map(item => {
      if (processStatus === item?.value && item?.isRetryAttempt) {
        const errParse =
          typeof data?.errors === 'string'
            ? JSON.parse(data?.errors)
            : data?.errors;

        return errParse?.retryAttempt
          ? `${item?.label} (${errParse?.retryAttempt})`
          : item?.label;
      } else if (processStatus === item?.value) return item?.label;
    });
  };

  const getStatusColor = text => {
    const findItem = Jsons.alertStatus.find(item => text === item?.value);
    return findItem?.color;
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      getAlertsListData(1);
    }
  };

  const getToolTipErrorMsg = item => {
    if (!item?.errors) return null;

    const errParse =
      typeof item?.errors === 'string'
        ? JSON.parse(item?.errors)
        : item?.errors;

    return errParse?.message || JSON.stringify(item?.errors);
  };

  // render ui
  const renderFilterSection = () => {
    return (
      <Card sx={Styles.searchContainer}>
        {/* search view */}
        <MDBox sx={Styles.searchView}>
          {/* client */}
          <LabelFilterView label={Strings.client}>
            <ClientDropdown
              isNotSetVisible
              selectedValue={clientId}
              storeClients={storeClients}
              setStoreClients={setStoreClients}
              onChange={value => setClientId(value)}
              style={Styles.inputView}
            />
          </LabelFilterView>
          {/* status */}
          <LabelFilterView label={Strings.status}>
            <Select
              showSearch
              value={status}
              size={'large'}
              placeholder={Strings.selectStatus}
              optionFilterProp="label"
              onChange={value => setStatus(value)}
              options={Jsons.alertStatus}
              style={Styles.inputView}
            />
          </LabelFilterView>
          {/* descriptor */}
          <LabelFilterView label={Strings.descriptor}>
            <Input
              ref={inputRef}
              value={descriptor}
              placeholder={Strings.enterDescriptor}
              size={'large'}
              onChange={text => setDescriptor(text?.target?.value)}
              style={Styles.inputView}
              onKeyDown={handleKeyDown}
            />
          </LabelFilterView>
          {/* case id */}
          <LabelFilterView label={Strings.caseId}>
            <Input
              ref={inputRef}
              value={caseId}
              placeholder={Strings.enterCaseId}
              size={'large'}
              onChange={text => setCaseId(text?.target?.value)}
              style={Styles.inputView}
              onKeyDown={handleKeyDown}
            />
          </LabelFilterView>
          {/* data source */}
          <LabelFilterView label={Strings.dataSource}>
            <Select
              showSearch
              value={dataSource}
              size={'large'}
              placeholder={Strings.selectDataSource}
              optionFilterProp="label"
              onChange={value => setDataSource(value)}
              options={Jsons.dataSource}
              style={Styles.inputView}
            />
          </LabelFilterView>
          {/* case date */}
          <LabelFilterView label={Strings.caseDate}>
            <RangePicker
              maxDate={dayjs()}
              value={caseDateString}
              size={'large'}
              presets={Jsons.dateRangePresets}
              onChange={(dateStrings, date) => {
                setCaseDateString(dateStrings);
                setCaseDate(date);
              }}
              style={Styles.inputView}
            />
          </LabelFilterView>
        </MDBox>
        {/* search btn view */}
        <MDBox sx={Styles.searchBtnView}>
          <MDButton
            // disabled={checkIsSearchDisabled()}
            variant="outlined"
            sx={Styles.resetBtn}
            onClick={() => handleResetClicked(1)}>
            {Strings.reset}
          </MDButton>
          <MDButton onClick={() => getAlertsListData(1)}>
            {Strings.search}
          </MDButton>
        </MDBox>
      </Card>
    );
  };

  const renderClientNameRowView = merchantName => {
    if (merchantName) return merchantName;
    else
      return (
        <MDBox
          sx={{
            ...Styles.badgeView,
            backgroundColor: 'DarkGray',
          }}>
          <MDTypography variant="text" color="black">
            {Strings.notSet}
          </MDTypography>
        </MDBox>
      );
  };

  const renderTableSection = () => {
    return (
      <DataTable
        currentPage={currentPage}
        totalPage={paginationMeta?.count}
        paginationOnChange={page => getAlertsListData(page)}
        table={{
          columns: [
            {Header: Strings.alertId, accessor: 'alertId'},
            {Header: Strings.client, accessor: 'client'},
            {Header: Strings.caseId, accessor: 'caseId'},
            {Header: Strings.descriptor, accessor: 'descriptor'},
            {Header: Strings.amount, accessor: 'amount'},
            {Header: Strings.status, accessor: 'status'},
            {Header: Strings.dataSource, accessor: 'dataSource'},
            {Header: Strings.txnDate, accessor: 'txnDate'},
            {Header: Strings.caseDate, accessor: 'caseDate'},
            {Header: Strings.alertCreated, accessor: 'alertCreated'},
          ],
          rows: storeAlerts?.map(item => {
            return {
              alertId: (
                <MDButton
                  variant="text"
                  color="info"
                  onClick={() => handleDetailsClicked(item)}
                  sx={Styles.caseIdBtn}>
                  {item?.id || '--'}
                </MDButton>
              ),
              client: renderClientNameRowView(item?.merchantName),
              caseId: item?.caseNumber || '--',
              descriptor: item?.descriptor || '--',
              amount: formatPrice(item?.amount, item?.currency),
              status: (
                <Tooltip title={getToolTipErrorMsg(item)}>
                  <MDBox
                    sx={{
                      ...Styles.badgeView,
                      backgroundColor: getStatusColor(item?.processStatus),
                    }}>
                    <MDTypography variant="text" color="black">
                      {getStatusText(item)}
                    </MDTypography>
                  </MDBox>
                </Tooltip>
              ),
              dataSource: item?.dataSource || '--',
              txnDate: formatDate(item?.transactionDate),
              caseDate: formatDate(item?.caseDate),
              alertCreated: formatDateAndTime(item?.createdAt),
            };
          }),
        }}
      />
    );
  };

  const renderInfoLabel = (label, value) => {
    return (
      <MDBox sx={Styles.infoLabelView}>
        <MDTypography variant="body2" fontWeight="bold" mr={1}>
          {label}:
        </MDTypography>
        <MDTypography variant="body2" fontWeight="regular" color="text">
          {value || '--'}
        </MDTypography>
      </MDBox>
    );
  };

  const renderDetailsModal = () => {
    return (
      <Modal
        title={Strings.alertDetails}
        open={isModalVisible}
        footer={null}
        onCancel={handleModalCancel}
        width={'45%'}
        style={Styles.modalStyle}>
        <MDBox sx={Styles.modalBodyContainer}>
          <MDBox sx={Styles.infoLeftView}>
            {renderInfoLabel(Strings.caseId, alertInfoData?.caseNumber)}
            {renderInfoLabel(Strings.descriptor, alertInfoData?.descriptor)}
            {renderInfoLabel(Strings.dataSource, alertInfoData?.dataSource)}
            {renderInfoLabel(Strings.arn, alertInfoData?.arn)}
            {renderInfoLabel(
              Strings.txnDate,
              formatDate(alertInfoData?.transactionDate),
            )}
          </MDBox>
          <MDBox>
            {renderInfoLabel(
              Strings.caseDate,
              formatDate(alertInfoData?.caseDate),
            )}
            {renderInfoLabel(Strings.bin, alertInfoData?.binNumber)}
            {renderInfoLabel(Strings.cardLast4, alertInfoData?.last4)}
            {renderInfoLabel(
              Strings.amount,
              formatPrice(alertInfoData?.amount, alertInfoData?.currency),
            )}
            {renderInfoLabel(Strings.status, getStatusText(alertInfoData))}
          </MDBox>
        </MDBox>
      </Modal>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* search filter section */}
      {renderFilterSection()}
      {/* table section */}
      {renderTableSection()}
      {/* modal section */}
      {renderDetailsModal()}
      <Backdrop
        sx={theme => ({color: '#fff', zIndex: theme.zIndex.drawer + 1})}
        open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default MerchantPage;
